// vael custom styling, overrides mastodon-dark theme

.about {
  &__footer {
    color: $dark-text-color;
  }

  &__header {
    &__hero {
      background: lighten($ui-base-color, 4%);
    }

    p {
      color: $darker-text-color;
    }
  }

  &__meta {
    background: lighten($ui-base-color, 4%);

    &__divider {
      border-color: lighten($ui-base-color, 8%);
    }

    h4 {
      color: $darker-text-color;
    }
  }

  &__section {
    &__title {
      background: lighten($ui-base-color, 4%);
      color: $highlight-text-color;
    }

    &__body {
      border: 1px solid lighten($ui-base-color, 4%);
    }
  }

  &__domain-blocks {
    background: darken($ui-base-color, 4%);
    border: 1px solid lighten($ui-base-color, 4%);
    border-radius: 4px;

    &__domain {
      border-bottom: 1px solid lighten($ui-base-color, 4%);
      color: $darker-text-color;

      &:nth-child(2n) {
        background: darken($ui-base-color, 2%);
      }

      &:last-child {
        border-bottom: 0;
      }

      h6 {
        color: $secondary-text-color;
      }
    }
  }
}

.column {
  >.scrollable {
    //background: $ui-base-color;
    background: $gray-shade-2;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0;
    border-right: 0;
  }
}

.column-header__wrapper {
  &.active {
    box-shadow: 0 1px 0 rgba($highlight-text-color, 0.3);

    &::before {
      display: block;
      content: '';
      background: radial-gradient(ellipse,
          rgba($ui-highlight-color, 0.23) 0%,
          rgba($ui-highlight-color, 0) 60%);
    }
  }
}

.column-header {
  background: $gray-shade-3;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  border-radius: 4px 4px 0 0;

  &>.column-header__back-button {
    color: $highlight-text-color;
  }

  &.active {
    .column-header__icon {
      color: $highlight-text-color;
      text-shadow: 0 0 10px rgba($highlight-text-color, 0.4);
    }
  }
}


.column-header__button {
  // background: $ui-base-color;
  background: lighten($ui-base-color, 4%);
  border: 0;
  color: $darker-text-color;

  &:hover {
    color: lighten($darker-text-color, 7%);
  }

  &.active {
    color: $primary-text-color;
    background: lighten($ui-base-color, 8%);

    &:hover {
      color: $primary-text-color;
      background: lighten($ui-base-color, 8%);
    }
  }

  &:disabled {
    color: $dark-text-color;
    cursor: default;
  }
}

.column-header__collapsible {
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  color: $darker-text-color;
}

.column-header__collapsible-inner {
  // background: $ui-base-color;
  background: $gray-shade-1;
  padding: 15px;
}

.column-header__setting-btn {

  &:hover,
  &:focus {
    color: $darker-text-color;
    text-decoration: underline;
  }
}

.column-header__issue-btn {
  color: $warning-red;

  &:hover {
    color: $error-red;
    text-decoration: underline;
  }
}

.navigation-bar {
  color: $darker-text-color;

  strong {
    color: $secondary-text-color;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.navigation-panel__logo {
  display: none;
}

.button {
  background-color: darken($ui-highlight-color, 2%);
  color: $black;

  &:active,
  &:focus,
  &:hover {
    background-color: $ui-highlight-color;
  }

  &:focus-visible {
    outline: $ui-button-icon-focus-outline;
  }

  &--destructive {

    &:active,
    &:focus,
    &:hover {
      background-color: $error-red;
      transition: none;
    }
  }

  &:disabled,
  &.disabled {
    background-color: $ui-primary-color;
    cursor: default;
  }

  &.button-alternative {
    color: $inverted-text-color;
    background: $ui-primary-color;

    &:active,
    &:focus,
    &:hover {
      background-color: lighten($ui-primary-color, 4%);
    }
  }

  &.button-alternative-2 {
    background: $ui-base-lighter-color;

    &:active,
    &:focus,
    &:hover {
      background-color: lighten($ui-base-lighter-color, 4%);
    }
  }

  &.button-secondary {
    color: $darker-text-color;
    background: transparent;
    padding: 6px 17px;
    border: 1px solid $ui-primary-color;

    &:active,
    &:focus,
    &:hover {
      // border-color: $ui-button-secondary-focus-background-color;
      // color: $ui-button-secondary-focus-color;
      // background-color: $ui-button-secondary-focus-background-color;
      border-color: lighten($ui-primary-color, 4%);
      color: lighten($darker-text-color, 4%);
      text-decoration: none;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &.button-tertiary {
    background: transparent;
    padding: 6px 17px;
    color: $highlight-text-color;
    border: 1px solid $highlight-text-color;

    &:active,
    &:focus,
    &:hover {
      // background-color: $ui-button-tertiary-focus-background-color;
      // color: $ui-button-tertiary-focus-color;
      background: $ui-highlight-color;
      color: $primary-text-color;
      border: 0;
      padding: 7px 18px;
    }

    &:disabled {
      opacity: 0.5;
    }

    &.button--confirmation {
      color: $valid-value-color;
      border-color: $valid-value-color;

      &:active,
      &:focus,
      &:hover {
        background: $valid-value-color;
        color: $primary-text-color;
      }
    }

    &.button--destructive {
      color: $error-value-color;
      border-color: $error-value-color;

      &:active,
      &:focus,
      &:hover {
        background: $error-value-color;
        color: $primary-text-color;
      }
    }
  }
}

.account__header__content {
  color: $darker-text-color;
  font-size: 14px;
  font-weight: 400;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.account__header {
  overflow: hidden;
  background: lighten($ui-base-color, 4%);

  &.inactive {
    opacity: 0.5;

    .account__header__image,
    .account__avatar {
      filter: grayscale(100%);
    }
  }

  &__image {
    background: darken($ui-base-color, 4%);
    border-bottom: 1px solid lighten($ui-base-color, 8%);
  }

  &__bar {
    border-bottom: 1px solid lighten($ui-base-color, 8%);

    .avatar {
      .account__avatar {
        background: darken($ui-base-color, 8%);
        border: 2px solid $ui-base-color;
      }
    }
  }

  &__bio {
    .account__header__content {
      color: $primary-text-color;
    }

    .account__header__fields {
      border-radius: 4px;
      background: darken($ui-base-color, 4%);
      border: 0;

      dl {
        border-bottom-color: lighten($ui-base-color, 4%);
      }

      dt {
        background: transparent;
        color: $dark-text-color;
      }

      dd {
        color: $darker-text-color;
      }

      a {
        color: lighten($ui-highlight-color, 8%);
      }

      .verified {
        border: 1px solid rgba($valid-value-color, 0.5);
        margin-top: -1px;

        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          margin-top: 0;
        }

        &:last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        dt,
        dd {
          color: $valid-value-color;
        }

        a {
          color: $valid-value-color;
        }
      }
    }
  }

  &__extra {
    &__links {
      color: $darker-text-color;

      a {
        color: $darker-text-color;
        text-decoration: none;
        padding: 5px 10px;
        font-weight: 500;

        strong {
          font-weight: 700;
          color: $primary-text-color;
        }
      }
    }
  }

  &__account-note {
    color: $primary-text-color;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;

    label {
      color: $darker-text-color;
    }

    textarea {
      color: $secondary-text-color;
      background: transparent;
      border: 0;
      outline: 0;
      border-radius: 4px;

      &::placeholder {
        color: $dark-text-color;
        opacity: 1;
      }

      &:focus {
        background: $ui-base-color;
      }
    }
  }
}


// status stuff copied wholesale from old, needs cleanup

.status__content--with-action {
  cursor: pointer;
}

.status__content {
  clear: both;
}

.status__content,
.reply-indicator__content {
  position: relative;
  font-size: 15px;
  line-height: 22px;
  word-wrap: break-word;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px;
  color: $primary-text-color;

  &:focus {
    outline: 0;
  }

  &.status__content--with-spoiler {
    white-space: normal;

    .status__content__text {
      white-space: pre-wrap;
    }
  }

  .emojione {
    width: 20px;
    height: 20px;
    margin: -3px 0 0;
  }

  p {
    margin-bottom: 22px;
    white-space: pre-wrap;
    unicode-bidi: plaintext;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $secondary-text-color;
    text-decoration: none;
    unicode-bidi: isolate;

    &:hover {
      text-decoration: underline;

      .fa {
        color: lighten($dark-text-color, 7%);
      }
    }

    &.mention {
      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }

    .fa {
      color: $dark-text-color;
    }
  }

  a.unhandled-link {
    color: $highlight-text-color;
  }

  .status__content__spoiler-link {
    background: $action-button-color;

    &:hover,
    &:focus {
      background: lighten($action-button-color, 7%);
      text-decoration: none;
    }

    &::-moz-focus-inner {
      border: 0;
    }

    &::-moz-focus-inner,
    &:focus,
    &:active {
      outline: 0 !important;
    }
  }

  .status__content__text {
    display: none;

    &.status__content__text--visible {
      display: block;
    }
  }
}

.announcements__item__content {
  word-wrap: break-word;
  overflow-y: auto;

  .emojione {
    width: 20px;
    height: 20px;
    margin: -3px 0 0;
  }

  p {
    margin-bottom: 10px;
    white-space: pre-wrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $secondary-text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.mention {
      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }

    &.unhandled-link {
      color: $highlight-text-color;
    }
  }
}

.status__content.status__content--collapsed {
  max-height: 22px * 15; // 15 lines is roughly above 500 characters
}

.status__content__read-more-button,
.status__content__translate-button {
  display: block;
  font-size: 15px;
  line-height: 22px;
  color: $highlight-text-color;
  border: 0;
  background: transparent;
  padding: 0;
  padding-top: 16px;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.translate-button {
  margin-top: 16px;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  color: $dark-text-color;
}

.status__content__spoiler-link {
  display: inline-block;
  border-radius: 2px;
  background: transparent;
  border: 0;
  color: $inverted-text-color;
  font-weight: 700;
  font-size: 11px;
  padding: 0 6px;
  text-transform: uppercase;
  line-height: 20px;
  cursor: pointer;
  vertical-align: top;
}

.status__wrapper--filtered {
  color: $dark-text-color;
  border: 0;
  font-size: inherit;
  text-align: center;
  line-height: inherit;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  clear: both;
  border-bottom: 1px solid lighten($ui-base-color, 8%);

  &__button {
    display: inline;
    color: lighten($ui-highlight-color, 8%);
    border: 0;
    background: transparent;
    padding: 0;
    font-size: inherit;
    line-height: inherit;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

.focusable {
  &:focus {
    outline: 0;
    background: lighten($ui-base-color, 8%);

    .detailed-status,
    .detailed-status__action-bar {
      background: lighten($ui-base-color, 10%);
    }
  }
}

.status {
  padding: 16px;
  min-height: 54px;
  //border-bottom: 1px solid lighten($ui-base-color, 12%);
  border-bottom: 6px solid darken($ui-base-color, 12%);
  cursor: auto;

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  opacity: 1;
  animation: fade 150ms linear;

  .media-gallery,
  .video-player,
  .audio-player,
  .attachment-list {
    margin-top: 16px;
  }

  &.light {

    .status__relative-time,
    .status__visibility-icon {
      color: $light-text-color;
    }

    .status__display-name {
      color: $inverted-text-color;
    }

    .display-name {
      color: $light-text-color;

      strong {
        color: $inverted-text-color;
      }
    }

    .status__content {
      color: $inverted-text-color;

      a {
        color: $highlight-text-color;
      }

      &__spoiler-link {
        color: $primary-text-color;
        background: $ui-primary-color;

        &:hover,
        &:focus {
          background: lighten($ui-primary-color, 8%);
        }
      }
    }
  }

  &--in-thread {
    $thread-margin: 46px + 10px;

    border-bottom: 0;

    .status__content,
    .status__action-bar,
    .media-gallery,
    .video-player,
    .audio-player,
    .attachment-list,
    .picture-in-picture-placeholder,
    .status-card,
    .hashtag-bar {
      margin-inline-start: $thread-margin;
      width: calc(100% - ($thread-margin));
    }

    .status__content__read-more-button {
      margin-inline-start: $thread-margin;
    }
  }

  &--first-in-thread {
    border-top: 1px solid lighten($ui-base-color, 8%);
  }

  &__line {
    height: 16px - 4px;
    border-inline-start: 2px solid lighten($ui-base-color, 8%);
    width: 0;
    position: absolute;
    top: 0;
    inset-inline-start: 16px + ((46px - 2px) * 0.5);

    &--full {
      top: 0;
      height: 100%;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 16px - 4px;
        height: 46px + 4px + 4px;
        width: 2px;
        background: $ui-base-color;
        inset-inline-start: -2px;
      }
    }

    &--first {
      top: 16px + 46px + 4px;
      height: calc(100% - (16px + 46px + 4px));

      &::before {
        display: none;
      }
    }
  }
}

.status__relative-time {
  display: block;
  font-size: 15px;
  line-height: 22px;
  height: 40px;
  order: 2;
  flex: 0 0 auto;
  color: $dark-text-color;
}

.notification__relative_time {
  color: $dark-text-color;
  float: right;
  font-size: 14px;
  padding-bottom: 1px;
}

.status__visibility-icon {
  padding: 0 4px;
}

.status__display-name {
  color: $dark-text-color;
}

.status__info .status__display-name {
  max-width: 100%;
  display: flex;
  font-size: 15px;
  line-height: 22px;
  align-items: center;
  gap: 10px;
  overflow: hidden;

  .display-name {
    bdi {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__account {
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.status__info {
  font-size: 15px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.status-check-box__status {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;

  .detailed-status__display-name {
    color: lighten($inverted-text-color, 16%);

    span {
      display: inline;
    }

    &:hover strong {
      text-decoration: none;
    }
  }

  .media-gallery,
  .audio-player,
  .video-player {
    margin-top: 15px;
    max-width: 250px;
  }

  .status__content {
    padding: 0;
    white-space: normal;
  }

  .media-gallery__item-thumbnail {
    cursor: default;
  }
}

.status__prepend {
  padding: 16px;
  padding-bottom: 0;
  display: inline-flex;
  gap: 10px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: $dark-text-color;

  .status__display-name strong {
    color: $dark-text-color;
  }

  >span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.status__wrapper-direct {
  background: mix($ui-base-color, $ui-highlight-color, 95%);

  &:focus {
    background: mix(lighten($ui-base-color, 4%), $ui-highlight-color, 95%);
  }

  .status__prepend {
    color: $highlight-text-color;
  }
}

.status__action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  margin-top: 16px;
}

.detailed-status__action-bar-dropdown {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.detailed-status {
  background: lighten($ui-base-color, 4%);
  padding: 16px;
  border-top: 1px solid lighten($ui-base-color, 8%);

  &--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .status__content,
    .detailed-status__meta {
      flex: 100%;
    }
  }

  .status__content {
    font-size: 19px;
    line-height: 24px;

    .emojione {
      width: 24px;
      height: 24px;
      margin: -1px 0 0;
    }

    .status__content__spoiler-link {
      line-height: 24px;
      margin: -1px 0 0;
    }
  }

  .media-gallery,
  .video-player,
  .audio-player {
    margin-top: 16px;
  }

  .status__prepend {
    padding: 0;
    margin-bottom: 16px;
  }
}

.scrollable>div:first-child .detailed-status {
  border-top: 0;
}

.detailed-status__meta {
  margin-top: 16px;
  color: $dark-text-color;
  font-size: 14px;
  line-height: 18px;
}

.detailed-status__action-bar {
  background: lighten($ui-base-color, 4%);
  border-top: 1px solid lighten($ui-base-color, 8%);
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.detailed-status__wrapper-direct {

  .detailed-status,
  .detailed-status__action-bar {
    background: mix($ui-base-color, $ui-highlight-color, 95%);
  }

  &:focus {

    .detailed-status,
    .detailed-status__action-bar {
      background: mix(lighten($ui-base-color, 4%), $ui-highlight-color, 95%);
    }
  }

  .detailed-status__action-bar {
    border-top-color: mix(lighten($ui-base-color, 8%),
        $ui-highlight-color,
        95%);
  }

  .status__prepend {
    color: $highlight-text-color;
  }
}

.detailed-status__link {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}

.detailed-status__favorites,
.detailed-status__reblogs {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  margin-inline-start: 6px;
}




// below is copied from contrast theme
/*
.status__content a,
.reply-indicator__content a,
.edit-indicator__content a,
.link-footer a,
.status__content__read-more-button,
.status__content__translate-button {
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &.mention {
    text-decoration: none;

    span {
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      span {
        text-decoration: none;
      }
    }
  }
}

.status__content a,
.reply-indicator__content a,
.edit-indicator__content a {
  color: $highlight-text-color;
}

.nothing-here {
  color: $darker-text-color;
}

.report-dialog-modal__textarea::placeholder {
  color: $inverted-text-color;
}

.link-button:disabled {
  cursor: not-allowed;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none !important;
  }
}
*/
